import React from 'react';
import { FaFacebookSquare, FaSearchLocation, FaTelegram, FaPhoneAlt, FaWhatsapp, FaCommentDots } from 'react-icons/fa';

const phoneNumber = '+251913479647'; // Replace with your phone number
const mapEmbedUrl = 'https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d63041.97979353102!2d38.702073358801336!3d9.052476448390188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e0!4m3!3m2!1d9.0865341!2d38.725738799999995!4m5!1s0x164b8fd89df7ef1d%3A0xdf277e31d4a100a7!2zUmFoYSBmb3JlaWduIGVtcGxveW1lbnQgYWdlbmN5IOGIq-GIgyDhiaDhi43hjK0g4YiA4YyI4YitIOGIsOGIq-GKkyDhiLDhiKvhibDhipsg4Yqg4YyI4YqT4YqdIOGKpOGMgOGKleGIsg!3m2!1d9.0191936!2d38.7524635!5e0!3m2!1sen!2set!4v1724038335046!5m2!1sen!2set'; // Replace with your Google Maps embed URL

const ContactPage = () => {
  return (
    <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">Contact Us</h1>
      <p className="text-lg text-gray-600 mb-6">Get in touch with us through any of the following channels:</p>
      <div className='flex gap-4 mb-6'>
        <a href="https://www.facebook.com/profile.php?id=100083338112782" target="_blank" rel="noopener noreferrer">
          <FaFacebookSquare
            size={30}
            className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-blue-600 duration-300'
          />
        </a>
        <a href={`tel:${phoneNumber}`}>
          <FaPhoneAlt
            size={30}
            className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-green-600 duration-300'
          />
        </a>
        <a href="https://wa.me/+25127650320" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp
            size={30}
            className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-green-600 duration-300'
          />
        </a>
        <a href="https://t.me/Ueman" target="_blank" rel="noopener noreferrer">
          <FaTelegram
            size={30}
            className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-blue-400 duration-300'
          />
        </a>
        
        
      </div>
      <div className="w-full max-w-3xl mb-6">
        <iframe
          src={mapEmbedUrl}
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          title="Location Map"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactPage;
