import React, { useEffect } from 'react';
import Pana from '../assets/pana.png';
import Frame35 from '../assets/Frame 35.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Section = ({ subtitle, description, reverse, image }) => {
  return (
    <div className='w-full bg-white py-16 px-4 font-sans'>
      <div
        className={`max-w-[1240px] mx-auto flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''}`}
        data-aos={reverse ? 'fade-left' : 'fade-right'} 
      >
        <div className='flex flex-col justify-center md:w-1/2'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>{subtitle}</h1>
          <p>{description}</p>
          <Link to="/contact">
        <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg'>
          Contact Us
        </button>
        </Link>
        </div>
        <img className='w-full md:w-[500px] mx-auto my-4 md:my-0' src={image} alt={subtitle} />
      </div>
    </div>
  );
};

const Analytics = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
    });
  }, []);

  return (
    <>
      <Section
        subtitle='Post Deployment Training'
        description='We offer ongoing support to our candidates even after they have been successfully placed in their new roles abroad. Our commitment to their well-being extends beyond the initial placement, ensuring that they have the resources and assistance they need to thrive. This includes mediation services to address any concerns that may arise between employers and employees, conflict resolution to help navigate and resolve any workplace challenges, and repatriation assistance for those who may need to return home. By providing these comprehensive support services, we ensure that our candidates and their employers enjoy a smooth, positive, and sustainable working relationship throughout the duration of their employment.'
        reverse={false}
        image={Pana}
      />
      <Section
        subtitle='Pre Deployment Training'
        description='We provide comprehensive training programs that go beyond the basics, ensuring our candidates are fully equipped for success in their roles abroad. Our training includes in-depth cultural orientation to help candidates adapt smoothly to new environments, job-specific skills tailored to the demands of their positions, and language development courses that enhance communication abilities. By focusing on these critical areas, we ensure that our candidates are not only job-ready but also confident and capable of thriving in diverse international settings.'
        reverse={true}
        image={Frame35}
      />
    </>
  );
};

export default Analytics;
