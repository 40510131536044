import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import image from '../assets/image.png';
import image2 from '../assets/image 1.png';
import plane from '../assets/plane.jpg';

const About = () => {
  const [activeTab, setActiveTab] = useState('vision'); // Default to 'vision'

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="relative overflow-hidden bg-black text-gray-200 py-32 px-8">
      {/* Decorative Skewed Backgrounds */}
      <div className="absolute top-0 left-0 right-0 h-20 bg-[#00df9a] transform -skew-y-6 -z-10" />
      <div className="absolute bottom-0 left-0 right-0 h-20 bg-[#00df9a] transform skew-y-6 -z-10" />

      {/* Main Content */}
      <div className="text-center max-w-5xl mx-auto space-y-16 relative z-10">
        <section className="mb-16">
          <h1 className="text-5xl font-extrabold text-white mb-8" data-aos="fade-up">
            How Our Process Solves Obstacles and Problems
          </h1>
          <p className="text-xl text-gray-300 mb-8" data-aos="fade-up">
            At RAHA Foreign Employment Agency, we are dedicated to connecting talented professionals from Ethiopia with exceptional opportunities in the Gulf region. With a commitment to excellence and a deep understanding of the job market, we ensure a seamless process for our clients and candidates.
          </p>
        </section>

        

        <section className="grid grid-cols-1 md:grid-cols-2 gap-16 mb-16">
          {[image, image2].map((imgSrc, index) => (
            <div key={index} className="flex justify-center" data-aos="fade-up">
              <div className="relative overflow-hidden rounded-lg shadow-lg">
                <img src={imgSrc} alt={`Image ${index + 1}`} className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-black opacity-40"></div>
              </div>
            </div>
          ))}
        </section>

        <section
          className="relative bg-[#00df9a] py-16 px-14 rounded-lg shadow-lg text-center overflow-hidden"
          data-aos="fade-up"
        >
          {/* Background Image with Overlay */}
          <div className="absolute inset-0 bg-gray-900 opacity-60 z-0 mix-blend-overlay"></div>
          <div className="absolute inset-0 bg-cover bg-center z-0"></div>

          {/* Content */}
          <div className="relative z-10 space-y-8" data-aos="fade-up">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900" data-aos="zoom-in">
              We Believe in a Proven Process—The Key to Successful Global Placements
            </h2>
            <p className="text-lg md:text-xl text-gray-900" data-aos="fade-up">
              Contact us to find out how our process can help you achieve your global career aspirations.
            </p>
            <button className="bg-black text-white font-semibold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105" data-aos="fade-up">
              Contact Us →
            </button>
          </div>
        </section>
      </div>

      <div
  className="relative h-screen bg-center bg-cover rounded-2xl mx-4 sm:mx-8 md:mx-12 lg:mx-20 my-12"
  style={{ backgroundImage: `url(${plane})` }}
  data-aos="fade-up"
>
  {/* Dark Overlay */}
  <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
  
  {/* Content */}
  <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white space-y-8 px-4 sm:px-6 md:px-8 lg:px-12">
    <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold" data-aos="fade-up">
      Premier Staffing and Recruitment Services for UAE, Qatar, Lebanon, and Gulf Countries, Delivering Customized Workforce Solutions for Employers and Professionals
    </h1>
    <button className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 px-6 sm:py-3 sm:px-8 md:py-4 md:px-10 rounded-full transition duration-300 ease-in-out transform hover:scale-105" data-aos="fade-up">
      Contact Us →
    </button>
  </div>
</div>


<section className="mb-16 px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col sm:flex-row justify-center mb-8 space-y-2 sm:space-y-0 sm:space-x-2">
    <button
      className={`py-2 px-4 sm:px-6 rounded-lg text-sm sm:text-base font-semibold ${activeTab === 'vision' ? 'bg-gradient-to-r from-green-400 to-teal-500 text-white shadow-lg' : 'bg-gray-800 text-gray-300'} transition-transform duration-300 ease-in-out transform hover:scale-105`}
      onClick={() => setActiveTab('vision')}
      data-aos="fade-up"
    >
      Vision
    </button>
    <button
      className={`py-2 px-4 sm:px-6 rounded-lg text-sm sm:text-base font-semibold ${activeTab === 'mission' ? 'bg-gradient-to-r from-green-400 to-teal-500 text-white shadow-lg' : 'bg-gray-800 text-gray-300'} transition-transform duration-300 ease-in-out transform hover:scale-105`}
      onClick={() => setActiveTab('mission')}
      data-aos="fade-up"
    >
      Mission
    </button>
  </div>

  {/* Tab Content */}
  <div
    className="bg-[#00df9a] p-6 sm:p-8 lg:p-10 rounded-lg shadow-2xl border-t-4 border-green-400 mx-auto max-w-[600px] transition-transform duration-500 ease-in-out transform hover:scale-105"
    data-aos="fade-up"
  >
    {activeTab === 'vision' ? (
      <div className="flex flex-col items-center">
        <h2 className="text-2xl text-center sm:text-3xl font-bold text-gray-900 mb-4" data-aos="fade-up">Our Vision</h2>
        <p className="text-base sm:text-lg text-gray-900">
          For the past 11 years, we have aspired to be the leading service provider in Ethiopia's foreign employment sector, specializing in exporting talent to key destinations such as the UAE, Saudi Arabia, Kuwait, Qatar, and Jordan. Our goal has always been to establish ourselves as the foremost agency in facilitating seamless employment opportunities for Ethiopian professionals seeking to advance their careers abroad. By leveraging our extensive experience and deep understanding of both local and international employment markets, we are committed to delivering exceptional services that bridge the gap between Ethiopian talent and global opportunities. Our dedication to excellence and continuous improvement drives us to be at the forefront of the foreign employment industry, ensuring that we consistently meet and exceed the expectations of our clients and partners.
        </p>
      </div>
    ) : (
      <div className="flex flex-col items-center">
        <h2 className="text-2xl text-center sm:text-3xl font-bold text-gray-900 mb-4" data-aos="fade-up">Our Mission</h2>
        <p className="text-base sm:text-lg text-gray-900">
          We are dedicated to offering a diverse range of professional and flexible packages tailored to meet the unique needs of our customers across the UAE, Saudi Arabia, Kuwait, Qatar, and Jordan. Our comprehensive services cater to foreign employment workers, encompassing everything from recruitment and visa processing to on-ground support and compliance assistance. We strive to deliver exceptional solutions that not only address the immediate requirements of our clients but also support their long-term goals in the ever-evolving employment landscape. By providing these essential services, we aim to enhance the efficiency and success of our clients' workforce management and contribute to a smoother, more effective employment experience for workers and employers alike.
        </p>
      </div>
    )}
  </div>
</section>




    </div>
  );
};

export default About;
