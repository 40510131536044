import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      
      {/* Logo Image */}
      <Link to="/" className='cursor-pointer'>
        <img src={logo} alt="RAHA Logo" className='w-32' /> {/* Increased width */}
      </Link>
      
      {/* Desktop Menu */}
      <ul className='hidden md:flex'>
        <li className='p-4 cursor-pointer hover:text-gray-500 transition-colors duration-300 ease-in-out'>
          <Link to="/">Home</Link>
        </li>
        <li className='p-4 cursor-pointer hover:text-gray-500 transition-colors duration-300 ease-in-out'>
          <Link to="/services">Services</Link>
        </li>
        <li className='p-4 cursor-pointer hover:text-gray-500 transition-colors duration-300 ease-in-out'>
          <Link to="/about">About</Link>
        </li>
        <li className='p-4 cursor-pointer hover:text-gray-500 transition-colors duration-300 ease-in-out'>
          <Link to="/Contact">Socials</Link>
        </li>
      </ul>

      {/* Hamburger Icon for Mobile Menu */}
      <div onClick={handleNav} className='block md:hidden cursor-pointer'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Menu */}
      <ul
        className={
          nav
            ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500'
            : 'ease-in-out duration-500 fixed left-[-100%]'
        }
      >
        <Link to="/" className='m-4'>
          <img src={logo} alt="RAHA Logo" className='w-32' /> {/* Increased width */}
        </Link>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/">Home</Link>
        </li>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/services">Services</Link>
        </li>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/about">About</Link>
        </li>
        <li className='p-4'>
          <Link to="/socials">Socials</Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
