import React, { useEffect } from 'react';
import { DocumentTextIcon, GlobeAltIcon, UsersIcon } from '@heroicons/react/outline';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Cards = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="w-full py-[8rem] px-4 bg-white font-sans">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-6">
        {/* Card 1 */}
        <div
          className="w-full shadow-xl flex flex-col p-8 my-4 rounded-lg transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl hover:bg-gray-50"
          data-aos="fade-up"
        >
          <DocumentTextIcon className="w-20 mx-auto mt-[-3rem] text-gray-500" />
          <h2 className="text-2xl font-bold text-center py-4">Employer Services</h2>
          <p className="text-center font-medium py-4 mx-auto text-gray-600">
            We provide tailored recruitment campaigns, market insights, and consultation services to help employers make informed decisions and build a reliable workforce.
          </p>
          <Link to="/about">
      <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">
        Learn More
      </button>
    </Link>

        </div>

        {/* Card 2 */}
        <div
          className="w-full shadow-xl bg-gray-100 flex flex-col p-8 my-4 rounded-lg transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl hover:bg-gray-200"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <GlobeAltIcon className="w-20 mx-auto mt-[-3rem] text-gray-500" />
          <h2 className="text-2xl font-bold text-center py-4">Visa and Documentation Assistance</h2>
          <p className="text-center font-medium py-4 mx-auto text-gray-600">
            We provide comprehensive training, including cultural orientation, job-specific skills, and language development, ensuring our candidates are well-prepared for their roles abroad.
          </p>
          <Link to="/about">
      <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">
        Learn More
      </button>
    </Link>

        </div>

        {/* Card 3 */}
        <div
          className="w-full shadow-xl flex flex-col p-8 my-4 rounded-lg transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl hover:bg-gray-50"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <UsersIcon className="w-20 mx-auto mt-[-3rem] text-gray-500" />
          <h2 className="text-2xl font-bold text-center py-4">Recruitment and Staffing Solutions</h2>
          <p className="text-center font-medium py-4 mx-auto text-gray-600">
            We connect employers with highly qualified candidates, ranging from skilled professionals to semi-skilled laborers, across various sectors, including hospitality, healthcare, construction, and domestic work.
          </p>
          <Link to="/about">
      <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">
        Learn More
      </button>
    </Link>

        </div>
      </div>
    </div>
  );
};

export default Cards;
