import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
    setSuccess('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Simulate successful submission
    setSuccess('Thank you for subscribing!');
    setEmail(''); // Clear the input field after success
  };

  return (
    <div className='w-full py-16 text-white px-4'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4' data-aos='fade-right'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
            Want to be updated with our weekly Newsletter?
          </h1>
          <p>Don’t miss out—subscribe now and be the first to know!</p>
        </div>
        <div className='my-4' data-aos='fade-left'>
          <form onSubmit={handleSubmit} className='flex flex-col sm:flex-row items-center justify-between w-full'>
            <input
              className='p-3 flex w-full rounded-md text-black'
              type='email'
              placeholder='Enter Email'
              value={email}
              onChange={handleEmailChange}
            />
            <button
              type='submit'
              className='bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3'
            >
              Notify Me
            </button>
          </form>
          {error && <p className='text-red-500 mt-2'>{error}</p>}
          {success && <p className='text-green-500 mt-2'>{success}</p>}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
