import React, {useState, useEffect } from 'react';
import { ReactTyped } from 'react-typed';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const TypingEffect = ({ text, letterDelay }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;

    const typeLetter = () => {
      if (currentIndex < text.length) {
        setDisplayedText((prev) => prev + text[currentIndex]);
        currentIndex++;
        setTimeout(typeLetter, letterDelay);
      }
    };

    typeLetter();

    return () => clearTimeout(typeLetter);
  }, [text, letterDelay]);

  return <span>{displayedText}</span>;
};


const Hero = () => {
  useEffect(() => {
    AOS.init({
      duration: 1800,
    });
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div 
        className="text-white max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center relative z-10"
        data-aos="fade-up"
      >
        <p className="text-[#00df9a] font-bold p-2" data-aos="fade-down">
          Foreign employment agency 
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6" data-aos="zoom-in">
          Empowering Careers, Transforming Lives
        </h1>

        <div className="flex flex-col justify-center items-center py-6" data-aos="fade-up">
          <p className="md:text-2xl sm:text-1xl text-am font-semibold text-center">
            Overseas employment agency based in
          </p>

          
          <ReactTyped
            className="md:text-2xl sm:text-1xl text-sm font-semibold"
            strings={['Addis Aba', 'Ethiopia']}
            typeSpeed={100}
            backSpeed={100}
            backDelay={2000}
            loop
          />
        </div>

        <Link to="/contact">
          <button
            className="relative bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black overflow-hidden transition-transform duration-300 ease-in-out transform hover:scale-105"
            data-aos="fade-up"
          >
            <span className="relative z-10">Contact Us</span>
            <div className="absolute inset-0 bg-[#00df9a] transition-all duration-300 ease-in-out transform origin-bottom-left hover:bg-white hover:scale-150 rotate-45"></div>
          </button>
        </Link>


      </div>
      <div className="absolute bottom-0 left-0 right-0 h-20 bg-[#00df9a] transform skew-y-[-6deg]" />
      <div className="absolute top-[80%] left-0 right-0 h-20 bg-[#] transform skew-y-[6deg]" />
    </div>
  );
};

export default Hero;
