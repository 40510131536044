import React, { useEffect } from 'react';
import {
  FaFacebookSquare,
  FaTelegram,
  FaPhoneAlt,
  FaWhatsapp,
  FaSearchLocation,
  FaCommentDots
} from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out',
    });
  }, []);

  const phoneNumber = '+251927650320'; 
  // const imoId = 'username'; 



  return (
    <div className='border-t-2'>
      <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300 '>
      {/* Left Section */}
      <div data-aos="fade-up">
        <h1 className='text-3xl font-bold text-[#00df9a]'>RAHA.</h1>
        <p className='py-4'>
          Stay connected with us on social media for the latest updates, job postings, and industry news.
        </p>
        <div className='flex gap-4 mt-6'>
  <a href="https://www.facebook.com/profile.php?id=100083338112782" target="_blank" rel="noopener noreferrer">
    <FaFacebookSquare
      size={30}
      className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-blue-600 duration-300'
    />
  </a>
  <a href="https://maps.app.goo.gl/YbVsQ9qbV9RaYfdz6?g_st=awb" target="_blank" rel="noopener noreferrer">
    <FaSearchLocation
      size={30}
      className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-pink-600 duration-300'
    />
  </a>
  <a href="https://t.me/yourusername" target="_blank" rel="noopener noreferrer">
    <FaTelegram
      size={30}
      className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-blue-400 duration-300'
    />
  </a>
  <a href={`tel:${phoneNumber}`}>
    <FaPhoneAlt
      size={30}
      className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-green-600 duration-300'
    />
  </a>
  <a href="https://wa.me/yourphonenumber" target="_blank" rel="noopener noreferrer">
    <FaWhatsapp
      size={30}
      className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-green-600 duration-300'
    />
  </a>
  <a href={`mailto:rahaforeignemploymentagent@gmail.com`}>
    <FaCommentDots
      size={30}
      className='cursor-pointer transition-transform transform hover:scale-125 hover:rotate-12 hover:shadow-lg hover:text-green-600 duration-300'
    />
  </a>
</div>
      </div>

      {/* Center Section */}
      <div className='lg:col-span-2 flex flex-col gap-8 mt-6 lg:mt-0 '>
        <div className='flex flex-col items-center' data-aos="fade-up" data-aos-delay="200">
          <h6 className='font-medium text-gray-400 mb-4'>Company</h6>
          <ul className='space-y-2'>
          <li className='text-sm text-gray-600 hover:text-gray-700 transition-colors duration-300 cursor-pointer'>
          <Link to="/about">About</Link>
           </li>
            <li className='text-sm text-gray-600 hover:text-gray-700 transition-colors duration-300 cursor-pointer'>
              Claim
            </li>
            <li className='text-sm text-gray-600 hover:text-gray-700 transition-colors duration-300 cursor-pointer'>
              Policy
            </li>
            <li className='text-sm text-gray-600 hover:text-gray-700 transition-colors duration-300 cursor-pointer'>
              Terms
            </li>
          </ul>
        </div>
      </div>
    </div>
 
    </div>
    ); 
};

export default Footer;